import { AD_PLACEMENTS } from "@consts";

export const state = () => ({
	isExcoLoaded: false,
	isExcoSticky: false,
});

export const mutations = {
	SET_IS_EXCO_LOADED(state, value) {
		state.isExcoLoaded = value;
	},
	SET_IS_EXCO_STICKY(state, value) {
		if (value) {
			window.tude = window.tude || { cmd: [] };
			window?.tude.cmd.push(() => {
				window.tude.destroyAds([AD_PLACEMENTS.MOB_ADHESION], false);
			});
		}

		state.isExcoSticky = value;
	},
};

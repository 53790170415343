
export default {
	props: {
		playerId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			playerStarted: false,
		};
	},
	computed: {
		isExcoScriptLoaded() {
			return this.$store.state.ads.isExcoLoaded;
		},
	},
	watch: {
		isExcoScriptLoaded(value) {
			if (value) {
				this.initExcoPlayer();
			}
		},
	},
	mounted() {
		if (!this.playerStarted) {
			this.initExcoPlayer();
		}
	},
	beforeDestroy() {
		if (window.ExCoPlayer) {
			const excoAPI = window.ExCoPlayer?.connect?.();
			if (excoAPI) {
				try {
					excoAPI?.destroy?.();
					if (this.isSmallDevice) {
						this.removeExcoEventListeners();
					}
				} catch (error) {
					console.error(
						`Error while trying to destroy Exco ad ${error}`
					);
				}
			}
		}
	},
	methods: {
		excoEventCallback(event) {
			if (event.data.type === "exco-event") {
				const { name, metadata } = event.data;

				if (name === "player-sticky") {
					this.$store.commit("ads/SET_IS_EXCO_STICKY", true);
				}

				if (name === "player-static") {
					this.$store.commit("ads/SET_IS_EXCO_STICKY", false);
				}
			}
		},
		addEventListeners() {
			window.addEventListener("message", this.excoEventCallback);
		},
		removeExcoEventListeners() {
			window.removeEventListener("message", this.excoEventCallback);
		},
		initExcoPlayer() {
			if (window.ExCoPlayer) {
				const excoAPI = window.ExCoPlayer?.connect?.();
				if (excoAPI) {
					if (this.isSmallDevice) {
						this.addEventListeners();
					}
					excoAPI?.init?.();
					this.playerStarted = true;
				}
			}
		},
	},
};

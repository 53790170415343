var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"footer",attrs:{"id":"footer"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"footer__section--links"},[_c('div',{staticClass:"footer__section--app-links"},[_c('p',{staticClass:"hint"},[_vm._v(_vm._s(_vm.$t("FooterTextDownloadApp")))]),_vm._v(" "),_c('div',{staticClass:"badges"},[_c('a',{staticClass:"app-link",attrs:{"href":_vm.getAppLink('iOS'),"target":"_blank","title":_vm.$t('FooterButtonTitleAttributeAppStore')},on:{"click":function($event){return _vm.onAppLinkClick('iOS', 35)}}},[_c('picture',[_c('source',{attrs:{"srcset":require("assets/images/app-store/store-apple.webp"),"type":"image/webp"}}),_vm._v(" "),_c('img',{staticClass:"app-image",attrs:{"src":require("assets/images/app-store/store-apple.png"),"alt":_vm.$t('FooterButtonTitleAttributeAppStore'),"width":"150","height":"44"}})])]),_vm._v(" "),_c('a',{staticClass:"app-link",attrs:{"href":_vm.getAppLink('Android'),"target":"_blank","title":_vm.$t('FooterButtonTitleAttributeGooglePlay')},on:{"click":function($event){return _vm.onAppLinkClick('Android', 36)}}},[_c('picture',[_c('source',{attrs:{"srcset":require("assets/images/app-store/store-google.webp"),"type":"image/webp"}}),_vm._v(" "),_c('img',{staticClass:"app-image",attrs:{"src":require("assets/images/app-store/store-google.png"),"alt":_vm.$t(
										'FooterButtonTitleAttributeGooglePlay'
									),"width":"150","height":"44"}})])])])]),_vm._v(" "),_c('div',{staticClass:"footer__section--social-links"},[_c('p',{staticClass:"social__title"},[_vm._v("Follow Us:")]),_vm._v(" "),_c('div',{staticClass:"social__items"},_vm._l((_vm.socials),function(item,index){return _c('div',{key:index,staticClass:"social__item"},[_c('a',{attrs:{"href":item.link,"title":item.title,"aria-label":item.aria,"target":"_blank"}},[_c('img',{attrs:{"src":item.icon,"alt":item.aria,"width":_vm.isLargeDeviceOrWider ? 32 : 42,"height":_vm.isLargeDeviceOrWider ? 32 : 42}})])])}),0)])]),_vm._v(" "),_c('nav',{staticClass:"footer__section--nav"},[_c('ul',{staticClass:"nav"},_vm._l((_vm.footerLinks),function(link){return _c('li',{key:_vm.$t(link.labelDictionaryKey),staticClass:"nav__item"},[_c('a',{staticClass:"nav__link",attrs:{"href":_vm.$t(link.linkDictionaryKey),"title":_vm.$t(link.labelDictionaryKey),"rel":link.linkDictionaryKey ===
							'FooterSuggestionsUrl'
								? 'nofollow'
								: 'follow',"target":link.linkDictionaryKey ===
							'FooterSuggestionsUrl'
								? '_blank'
								: '_self'}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t(link.labelDictionaryKey))+"\n\t\t\t\t\t")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"footer__section--info"},[_vm._m(0),_vm._v(" "),_c('p',{staticClass:"copyright"},[_vm._v("\n\t\t\t\t© 1996-"+_vm._s(_vm.currentYear)+" LoveToKnow Media, except where\n\t\t\t\totherwise noted. All Rights Reserved.\n\t\t\t")]),_vm._v(" "),_c('a',{staticClass:"ccpa-link",attrs:{"href":_vm.$t('FooterCcpaUrl'),"title":_vm.$t('FooterCcpaText')}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("FooterCcpaText"))+"\n\t\t\t")]),_vm._v(" "),(_vm.isHomePage)?_c('a',{staticClass:"onetrust-link",on:{"click":_vm.onOneTrustLinkClick}},[_vm._v("\n\t\t\t\tCookie Settings\n\t\t\t")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"bg-helpers-blue"},[_c('div',{staticClass:"max-w-[1272px] mx-auto px-5 py-6 mdl:py-5 flex items-center text-white text-sm flex-wrap flex-col md:flex-row"},[_c('p',{staticClass:"font-bold md:mr-6 my-0"},[_vm._v("Our Helpers:")]),_vm._v(" "),_c('div',{staticClass:"flex flex-grow flex-wrap flex-col md:flex-row"},_vm._l((_vm.helpers),function(entry){return _c('a',{key:entry.linkText,staticClass:"flex items-center text-white no-underline flex-col md:flex-row hover:underline whitespace-nowrap mx-auto mt-6 md:mt-4 min-[1060px]:mt-0 md:ml-0 md:mr-6 md:last-of-type:mr-0 text-sm",attrs:{"href":entry.link,"title":entry.linkTitle,"target":"_blank"}},[_c('img',{staticClass:"mr-1.5 mb-1.5 md:mb-0",attrs:{"src":entry.iconPath,"alt":entry.iconAlt,"width":entry.iconWidth,"height":entry.iconHeight}}),_vm._v("\n\t\t\t\t\t"+_vm._s(entry.linkText)+"\n\t\t\t\t")])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('picture',[_c('source',{attrs:{"srcset":"/static/images/ic_wfw_header.webp","type":"image/webp"}}),_vm._v(" "),_c('img',{staticClass:"footer-logo",attrs:{"src":"/static/images/ic_wfw_header.png","alt":"wf logo Image","width":"58","height":"37"}})])
}]

export { render, staticRenderFns }
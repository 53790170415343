
import { AD_LAYOUT_STUBS, AD_PLACEMENTS, PAGES } from "@/common/consts";

export default {
	name: "BaseLayout",
	props: {
		isErrorLayout: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			AD_LAYOUT_STUBS,
			AD_PLACEMENTS,
		};
	},
	head() {
		return {
			title: this.$t(this.$route.meta.title),
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.$t(this.$route.meta.description),
				},
			],
		};
	},
	computed: {
		showLeaderboardAd() {
			if (
				(this.$route.name === PAGES.ARTICLE_DETAIL &&
					this.$store.state.articles.hideAds) ||
				this.$route.name === PAGES.GET_THE_APP
			) {
				return false;
			}

			return true;
		},
		showMobileTopBanner() {
			return [PAGES.WORDLE_HINTS, PAGES.WORDLE_ANSWERS].includes(
				this.$route.name
			);
		},
		headerClearanceHeight() {
			return this.$store.getters["window/headerClearanceHeight"];
		},

		renderMobileAdhesionAd() {
			if (
				(this.$route.name === PAGES.ARTICLE_DETAIL &&
					this.$store.state.articles.hideAds) ||
				this.$route.name === PAGES.GET_THE_APP ||
				this.$store.state.ads.isExcoSticky === true
			) {
				return false;
			}

			return true;
		},
		renderDesktopAdhesionAd() {
			if (
				[PAGES.WORDLE_HINTS, PAGES.WORDLE_ANSWERS].includes(
					this.$route.name
				)
			) {
				return true;
			}

			return false;
		},
		showLeaderboardAdWrapper() {
			return this.$route.name !== PAGES.GET_THE_APP;
		},
		renderAdhesionAdWrapper() {
			return this.renderMobileAdhesionAd || this.renderDesktopAdhesionAd;
		},
		showAdhesionAdWrapper() {
			/**
			 * We also do not want to show the adhesion ad at all
			 * if it's a mobile device that is short (i.e. the keyboard is open)
			 */
			if (
				this.$store.state.window.windowHeight !== null &&
				this.$store.state.window.windowHeight < 500 &&
				this.isSmallDevice
			) {
				return false;
			}

			return true;
		},
		showBreadcrumbsOnMobile() {
			return [PAGES.ABOUT].includes(this.$route.name);
		},
		breadcrumbItems() {
			const overrides =
				this.$store.state.navigation?.breadcrumbsOverride || [];
			if (overrides.length) {
				return overrides;
			}
			return this.$route.meta?.breadcrumbs || [];
		},
	},
};
